import React from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"

export default function Sitemap({ data }) {
    debugger;

    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>Wunderloop: Sitemap</title>
                <meta charset="utf-8" />
                <meta name="robots" content="noindex" />
            </head>

            <section className="bg-white">
                <div className="sectionFit-container bg-white pt-5">
                    <div className="row pt-5">
                        <h1 className="align-top h2-huge4 h1-darkBlue2">
                            Sitemap
                        </h1>
                    </div>
                </div>
            </section>

            <section className="bg-white pt-5">
                <div className="sectionFit-container bg-white pt-4">

                    <div className="row sitemap-box  pt-2 pl-0">
                        <div className="col-lg-3 pl-0">
                            <p className="align-top p-regular8 text-darkgray2">
                                Products
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <div className="row">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_product}
                                >
                                    Workplace Product Overview
                                </Button>
                            </div>
                            <div className="row pt-3">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_solution_automation}
                                >
                                    Automation and Rules
                                </Button>
                            </div>

                        </div>
                        <div className="col-lg-3 pl-0">
                            <div className="row">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_feature}
                                >
                                    Workplace Features
                                </Button>
                            </div>
                            <div className="row pt-3">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_mobileapp}
                                >
                                    Workplace Mobile App
                                </Button>
                            </div>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <div className="row">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_solution_occupancy}
                                >
                                    Occupancy Management
                                </Button>
                            </div>
                            <div className="row pt-3">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_pricing}
                                >
                                    Workplace Pricing
                                </Button>
                            </div>

                        </div>
                    </div>

                    <div className="row sitemap-box pt-2 pl-0">
                        <div className="col-lg-3 pl-0">
                            <p className="align-top p-regular8 text-darkgray2">
                                Solutions
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <div className="row">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_solution_deskbooking}
                                >
                                    Workplace Desk Booking
                                </Button>
                            </div>
                            <div className="row pt-3">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_solution_workexperience}
                                >
                                    Work Experience
                                </Button>
                            </div>

                        </div>
                        <div className="col-lg-3 pl-0">
                            <div className="row">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_solution_roombooking}
                                >
                                    Workplace Room Booking
                                </Button>
                            </div>
                            <div className="row pt-3">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_solution_space}
                                >
                                    Space Management
                                </Button>
                            </div>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <div className="row">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_solution_hybridwork}
                                >
                                    Hybrid Work
                                </Button>
                            </div>
                            <div className="row pt-3">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--primaryPurple3'
                                    buttonSize='btn--txt3'
                                    href={config.navigationURL_sustainability}
                                >
                                    Sustainability
                                </Button>
                            </div>

                        </div>
                    </div>

                    <div className="row sitemap-box  pt-2 pl-0">
                        <div className="col-lg-3 pl-0">
                            <p className="align-top p-regular8 text-darkgray2">
                                About
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <Button
                                className='btns'
                                buttonStyle='btn--primaryPurple3'
                                buttonSize='btn--txt3'
                                href={config.navigationURL_aboutUs}
                            >
                                About Wunderloop
                            </Button>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <Button
                                className='btns'
                                buttonStyle='btn--primaryPurple3'
                                buttonSize='btn--txt3'
                                href={config.navigationURL_blog}
                            >
                                Blog
                            </Button>
                        </div>
                    </div>

                    <div className="row sitemap-box  pt-2 pl-0">
                        <div className="col-lg-3 pl-0">
                            <p className="align-top p-regular8 text-darkgray2">
                                Legal
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <Button
                                className='btns'
                                buttonStyle='btn--primaryPurple3'
                                buttonSize='btn--txt3'
                                href={config.navigationURL_privacy}
                            >
                                Privacy & Terms
                            </Button>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <Button
                                className='btns'
                                buttonStyle='btn--primaryPurple3'
                                buttonSize='btn--txt3'
                                href={config.navigationURL_cookie}
                            >
                                Cookie settings
                            </Button>
                        </div>
                        <div className="col-lg-3 pl-0">
                        </div>
                    </div>

                    <div className="row sitemap-box  pt-2 pl-0">
                        <div className="col-lg-3 pl-0">
                            <p className="align-top p-regular8 text-darkgray2">
                                Contact
                            </p>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <Button
                                className='btns'
                                buttonStyle='btn--primaryPurple3'
                                buttonSize='btn--txt3'
                                href={config.navigationURL_contactSales}
                            >
                                Contact Wunderloop
                            </Button>
                        </div>
                        <div className="col-lg-3 pl-0">
                            <Button
                                className='btns'
                                buttonStyle='btn--primaryPurple3'
                                buttonSize='btn--txt3'
                                href={config.navigationURL_support}
                            >
                                Contact Support
                            </Button>
                        </div>
                        <div className="col-lg-3 pl-0">
                        </div>
                    </div>

                </div>
            </section>

        </Layout >
    );
}
